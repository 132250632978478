/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 100px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(96, 131, 166);
  border-radius: 10px;
}
